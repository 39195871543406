@import "~bootstrap/scss/bootstrap";
@import url("https://use.typekit.net/vxl1pxi.css");

@font-face {
    font-family: "Equinox Regular"; /*Can be any text*/
    src: local("equinox-regular"), url("../fonts/equinox-regular.woff") format("woff");
}

@font-face {
    font-family: "Equinox Bold"; /*Can be any text*/
    src: local("equinox-bold"), url("../fonts/equinox-bold.woff") format("woff");
}

html,
body {
}
body {
    position: fixed;
    width: 100vw;
    color: white;
    background-color: black;

    height: 100vh;
    height: -webkit-fill-available;

    overflow: hidden;

    font-family: "montserrat", sans-serif;
}
.app-container {
    min-height: 100vh;
    overscroll-behavior: none;
}

.vv-nav {
    text-transform: uppercase;
}

.rulebook-page-container {
    .rulebook-page {
        img {
            width: 100%;
        }
        padding-bottom: 20px;
    }
    overflow: scroll;
}

.vv-logo {
    background-image: url("/img/global/v-logo.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 10px;
    width: 40px;
    height: 40px;
}

.nav-search {
    margin: 0 0 0 10px;
}

.App {
    text-align: center;
    background-color: black;
    width: 100vw;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
    opacity: 0.2;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

#tools-panel {
    position: absolute;
    z-index: 100;
    top: 0;
    right: -200px;
    bottom: 0;
    height: 100%;
    width: 200px;
    padding-top: 5px;
    margin-right: 0;
    margin-left: auto;
    margin-bottom: 0;
    margin-top: 0;
    background-color: black;
    overflow-y: scroll;
    overflow-x: hidden;
    overscroll-behavior: none;
    -webkit-overflow-scrolling-y: scroll;
    cursor: pointer;

    transition: right 0.2s ease-out;

    &.tools-open {
        right: 0;
    }

    &.missions {
        > div.mission-list {
            display: block;
        }
    }
    &.objects {
        > div.object-list {
            display: block;
        }
    }
}

#canvas {
    background-color: blue;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    margin: 0;
    position: absolute;
    bottom: 0;
    right: 0;
}
canvas {
    position: absolute;
    background-color: green;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    margin: 0;
    bottom: 0;
    right: 0;
}
.canvas-wrapper {
    position: absolute;
    height: 100vh !important;
    width: 100vw;
    top: 0;
    left: 0;
    margin: 0;
    bottom: 0;
    right: 0;
}

.mission-object {
    display: block;
    background-color: #222222;
    text-decoration: none;
    font-weight: bold;
    color: white;
    height: 160px;
    margin-bottom: 5px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;

    &:hover {
        color: white;
    }
    > span {
        display: inline-block;
        align-self: flex-end;
        background-color: #000055;
        width: 100%;
        padding: 3px 0 3px 0;
        border-radius: 0 0 5px 5px;
    }
}

#mission-title {
    position: absolute;
    bottom: 14px;
    left: 30px;
    padding: 0;
    display: block;
    font-weight: bold;
    font-size: 40pt;
    color: white;
}

.panel-tabs {
    position: absolute;
    display: block;
    top: 70px;
    right: 0;
    cursor: pointer;
    text-align: right;

    transition: right 0.2s ease-out;

    &.tools-open {
        right: 200px;
    }

    &.missions {
        > div.missions-tab {
            background-color: black;
            border-style: solid;
        }
    }
    &.objects {
        > div.objects-tab {
            background-color: black;
            border-style: solid;
        }
    }
}

.panel-tab {
    display: block;
    width: 60px;
    height: 60px;
    background-color: #333333;
    margin-bottom: 2px;
    border-radius: 10px 0 0 10px;
    background-position: center;
    background-size: 40%;
    background-repeat: no-repeat;
    border-style: none;
    border-color: gray;
    border-right: 0;

    &.objects-tab {
        background-image: url("/img/ui/objects.svg");
    }
    &.missions-tab {
        background-image: url("/img/ui/missions.svg");
    }
    &.close-tab {
        background-image: url("/img/ui/double-arrow-right.svg");
    }
    &.locked-tab {
        display: none;
        background-image: url("/img/ui/locked.svg");
    }
}

.close-tab {
    visibility: hidden;
    background-color: black;
    height: 30px;
    width: 30px;
    margin-right: 0;
    margin-left: auto;
    margin-bottom: 30px;
    &.tools-open {
        visibility: visible;
    }
}

.property-list {
    display: none;
    padding: 20px;
    padding-top: 70px;
    text-align: left;
    div.property-item {
        label {
            display: block;
            color: #cccccc;
            font-weight: bold;
        }
        input,
        textarea {
            width: 100%;
            border: 1px;
            border-color: #666666;
            background-color: #444444;
            color: white;
            padding: 5px 10px;
        }
    }
}

.mission-list {
    display: none;
    padding: 10px;
    div {
        &.new-mission-button {
            text-align: left;
            text-transform: uppercase;
            font-weight: 800;
            font-size: 0.8em;
            padding: 8px 8px 8px 10px;
            background-color: green;
            border-radius: 5px;
            margin-bottom: 10px;
            background-image: url("/img/ui/plus.svg");
            background-position: right 10px center;
            background-size: 20px;
            background-repeat: no-repeat;
        }
    }
}

.mission-item {
    display: block;
    background-color: #222222;
    text-decoration: none;
    font-weight: bold;
    color: white;
    height: 160px;
    margin-bottom: 5px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;

    &:hover {
        color: white;
    }
    > span {
        display: inline-block;
        align-self: flex-end;
        background-color: #550055;
        width: 100%;
        padding: 3px 0 3px 0;
        border-radius: 0 0 5px 5px;
    }
}

.object-list {
    display: none;
    padding: 10px;
    > .row {
        margin-top: 56px;
    }
}

.meta-tab {
    display: block;
    width: 60px;
    height: 60px;
    background-color: #333333;
    margin-bottom: 2px;
    border-radius: 0 10px 10px 0;
    background-position: center;
    background-size: 40%;
    background-repeat: no-repeat;
    border-style: none;
    border-color: gray;
    border-left: 0;

    text-align: center;

    svg {
        width: 70%;
        height: 70%;
        margin: 15%;
        // top: -50%;
    }

    &.rules-tab {
        //background-image: url('/img/ui/objects.svg');
    }

    &.properties-tab {
        //background-image: url('/img/ui/missions.svg');
    }

    &.meta-close-tab {
        background-image: url("/img/ui/double-arrow-left.svg");
    }
}

.meta-tabs {
    position: absolute;
    display: block;
    top: 70px;
    left: 0;
    cursor: pointer;
    text-align: left;

    transition: left 0.2s ease-out;

    &.meta-open {
        left: 400px;
    }

    &.rules {
        > div.rules-tab {
            background-color: black;
            border-style: solid;
        }
    }
    &.properties {
        > div.properties-tab {
            background-color: black;
            border-style: solid;
        }
    }
}

.meta-close-tab {
    visibility: hidden;
    background-color: black;
    height: 30px;
    width: 30px;
    margin-right: auto;
    margin-left: 0;
    margin-bottom: 30px;
    &.meta-open {
        visibility: visible;
    }
}

#meta-panel {
    position: absolute;

    z-index: 1;
    top: 0;
    left: -400px;
    bottom: 0;
    height: 100%;
    width: 400px;
    padding-top: 5px;
    margin-right: 0;
    margin-left: auto;
    margin-bottom: 0;
    margin-top: 0;
    background-color: black;
    overflow-y: scroll;
    overflow-x: hidden;
    overscroll-behavior: none;
    -webkit-overflow-scrolling-y: scroll;
    cursor: pointer;

    transition: left 0.2s ease-out;

    &.meta-open {
        left: 0;
    }

    &.rules {
        > div.rules-list {
            display: block;
        }
    }
    &.properties {
        > div.property-list {
            display: block;
        }
    }
}

.rules-list {
    display: none;
    text-align: left;
    padding: 20px;
    > .row:first-child {
        margin-top: 46px;
    }
    .rules-items {
        padding: 0;
    }
    .rule-item {
        border: 1px solid #555555;
        border-radius: 5px;
        padding: 5px 10px;
        margin-bottom: 10px;
    }
}

.rules-editor {
    &.preview {
        display: none;
    }
    &.edit {
        display: block;
    }
    > .editor {
        display: block;
        width: 100%;
        background-color: #444444;
        color: #dddddd;
        border-radius: 5px;
        padding: 5px 10px;

        overflow-x: auto;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
    }
}

.rules-markdown {
    &.preview {
        display: block;
    }
    &.edit {
        display: none;
    }

    p {
        margin-bottom: 1.5rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        clear: both;
    }

    blockquote {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 40px;
        margin-inline-end: 40px;
    }

    pre {
        overflow-x: auto;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
    }

    img {
        max-width: 35%;
    }

    pre {
        border: 1px solid #444444;
        background-color: #222222;
        border-radius: 3px;
        padding: 4px 8px;
    }
    table {
        border-width: 1px 0px 1px 0;
        border-style: solid;

        border-radius: 3px;
    }
    th,
    td {
        padding: 4px 6px;
    }
    thead {
        background-color: #333333;
    }
    tr:nth-child(even) {
        background: #222222;
    }
    //tr:nth-child(odd) {background: #444444}

    p {
        display: block;
    }

    .vv-ref {
        display: block;
        float: left;
        white-space: nowrap;

        width: 50px;
        padding: 14px 0;
        margin-right: 10px;
        margin-bottom: 1.2rem;
        background-image: url("/img/ui/rules/reference.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        text-align: center;
        font-weight: 800;
        font-size: 1.5em;
    }
}

.toolset {
    //position: absolute;
    display: block;
    margin-bottom: 10px;
    padding-right: 10px;

    > .tool-button {
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        background-color: transparent;
        border-width: 1px;
        border-style: solid;
        border-color: white;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: 10px;

        &.highlight {
            background-image: url("/img/ui/toolset/highlight-inactive.png");
            &.true {
                background-image: url("/img/ui/toolset/highlight-active.png");
            }
        }
    }
}

.utility-buttons {
    position: absolute;
    display: block;
    bottom: 20px;
    margin-right: 20px;
    right: 0;
    cursor: pointer;

    transition: right 0.2s ease-out;

    &.tools-open {
        right: 200px;
    }
}

.utility-button {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    border-color: white;
    border-width: 1px;
    border-style: solid;
    background-position: center;
    background-size: 40%;
    background-repeat: no-repeat;
    margin-bottom: 10px;

    &.download-mission {
        background-image: url("/img/ui/download.svg");
    }
    &.upload-mission {
        background-image: url("/img/ui/download.svg");
        -moz-transform: scaleY(-1);
        -o-transform: scaleY(-1);
        -webkit-transform: scaleY(-1);
        transform: scaleY(-1);
        filter: FlipV;
        -ms-filter: "FlipV";
    }
    &.delete-mission {
        background-image: url("/img/ui/tash.svg");
    }
    &.locked-tab {
        display: none;
    }
}

.modal-dialog {
    color: black;
}

.locked-icon {
    width: 20px;
    position: absolute;
    margin: 5px;
    right: 8px;
}

.mission-category {
    background-color: #333333;
    border-radius: 10px 10px 0 0;
    padding: 8px;
    margin-bottom: 10px;
    margin-top: 16px;
    font-size: 1.14em;
    font-weight: 700;
}

.dropbox-missions-button {
    background-color: #0061ff;
    font-weight: 700;
    border-radius: 5px;
    padding: 8px;
}

.export-missions-button {
    display: block;
    background-color: #444444;
    font-weight: 700;
    border-radius: 5px;
    padding: 8px;
    color: white;
    text-decoration: none;
    margin-bottom: 5px;

    &:visited,
    &:hover {
        color: white;
    }
}

.mission-object-type {
    display: block;
    background-color: #333333;
    border-radius: 10px 10px 0 0;
    padding: 8px;
    margin-bottom: 10px;
    margin-top: 16px;
    font-size: 1.14em;
    font-weight: 700;
}

#ships,
#modules,
#module-attributes,
#specialists,
#commanders,
#rulebook {
    height: 100vh;
    overflow-y: scroll;
    padding-top: 80px;
    padding-bottom: 60px;

    @media print {
        height: unset;
        overflow-y: unset;
    }
}
#missions {
    padding: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.faction-row {
    //padding-top: 10px;
    //padding-bottom: 10px;

    //page-break-after: always;
    page-break-inside: avoid;
}
#module-attributes h2,
h2.faction-name {
    text-align: center;
    text-transform: uppercase;
    font-size: 28pt;
    letter-spacing: 20px;
    margin-top: 10px;
    font-weight: 300;
    font-family: "Equinox Bold", sans-serif;
}
h3 {
    font-size: 18pt;
    font-weight: bold;
    margin-bottom: 0;
}
p {
    font-size: 12pt;
    line-height: 16pt;
}
.faction-emblem {
    width: 180px;
    margin-bottom: 20px;
}
.ship-container {
    border-radius: 12px;
    background-size: 100% 50%, 200px, cover;
    background-repeat: no-repeat;
    background-position: center bottom, right 20px top 20px, center top;

    margin-bottom: 30px;

    display: flex;

    > .ship-id {
        .ship-name {
            font-family: 'Equinox Bold', sans-serif;
            font-size: 1.4em;
            margin-bottom: 0.2em;
        }
        text-align: left;
        text-transform: uppercase;
        font-size: 1.3em;
        letter-spacing: 5px;
        font-weight: 500;
        display: inline-block;
        align-self: flex-start;
        width: 100%;
        padding: 20px 0 20px 20px;
        line-height: 0.9em;
        > .ship-class {
            font-weight: 300;
            font-size: 0.6em;
        }
    }
}

.ship-stats {
    margin-top: 60px;
}

.stat-block {
    text-align: left;
    margin-top: 4px;
    margin-bottom: 10px;
    > .stat-name {
        font-size: 0.7em;
        letter-spacing: 0;
    }

    .stat-chart {
        > span {
            display: inline-block;
            width: 18px;
            height: 18px;
            background-color: white;
            margin-right: 2px;
            border-radius: 3px;
        }
    }
}
.ship-attributes {
    > .ship-attribute {
        display: inline-block;
        width: 60px;
        height: 60px;
        margin-right: 3px;
        background-repeat: no-repeat;
        background-size: 65%;
        background-position: left center;

        > span {
            margin-top: 36px;
            margin-right: 5px;
            text-align: right;
            display: block;
            align-self: flex-end;
        }

        &.capacitor {
            background-image: url("/img/ui/ship-stats/capacitor.svg");
        }
        &.modules {
            background-image: url("/img/ui/ship-stats/modules.svg");
        }
        &.crew {
            background-image: url("/img/ui/ship-stats/crew.svg");
        }
        &.specialists {
            background-image: url("/img/ui/ship-stats/specialist.svg");
        }
        &.specialist-cards {
            background-image: url("/img/ui/ship-stats/cards.svg");
        }
        &.recovery {
            background-image: url("/img/ui/ship-stats/recover.svg");
        }
        &.thrust {
            background-image: url("/img/ui/ship-stats/thrust.svg");
        }
        &.yaw {
            background-image: url("/img/ui/ship-stats/yaw.svg");
        }
        &.vector {
            background-image: url("/img/ui/ship-stats/vector.svg");
        }
    }
}

.commander-container {
    border-radius: 12px;
    background-size: 100% 50%, 13%, cover;
    background-repeat: no-repeat;
    background-position: center bottom, right 20px top 20px, center top;

    //height: 400px;
    //width: 200px;

    margin-bottom: 30px;

    display: block;
    text-align: left;
    padding: 14px;

    > .commander-id {
        text-align: right;
        text-transform: uppercase;
        font-size: 1.1em;
        letter-spacing: 5px;
        font-weight: 500;
        display: block;
        width: 100%;
        margin-top: 160px;

        padding: 20px 0 20px 20px;
        line-height: 0.9em;
        > .commander-title {
            font-weight: 300;
            font-size: 0.6em;
        }
    }

    .commander-stats {
        width: 100%;

        .stat {
            width: 50px;
            height: 50px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            text-align: center;
            display: inline-flex;
            font-size: 0.9em;
            font-weight: 600;
            > div {
                width: 100%;
                text-align: center;
                align-self: center;
                //padding-left: 3px;
            }
            &:first-child {
                margin-right: 0px;
            }
        }
        .strategic {
            background-image: url("/img/ui/commander-stats/strategic.svg");
        }
        .tactical {
            background-image: url("/img/ui/commander-stats/tactical.svg");
        }
    }
    .commander-effects {
        .commander-effect {
            .effect-cost {
                background-image: linear-gradient(to left, #882461ff, #882461ff, #88246100);
                border: 4px solid #882461;
                border-width: 4px 0 4px 4px;
                border-radius: 18px 32px 0 18px;

                img {
                    width: 24px;
                    margin: 4px;
                }
            }

            .effect-name {
                font-weight: 600;
                font-size: 1em;
                text-transform: uppercase;
                display: flex;
                > span {
                    align-self: center;
                }
            }

            .effect-description {
                margin: 4px 34px 14px 34px;
                font-size: 0.85em;
                line-height: 0.9em;
            }

            &.passive {
                .effect-cost {
                    img {
                        width: 18px;
                        margin: 8px;
                    }
                    background-image: unset;
                    border: unset;
                    border-width: unset;
                    border-radius: unset;
                }
                .effect-name {
                    margin-bottom: 0;
                }
                .effect-description {
                    margin-top: -5px;
                }
            }
        }
    }
}

.module-type-name {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.8em;
    letter-spacing: 20px;
    //margin-left: 20px;
    font-weight: 300;
    font-family: 'Equinox Bold', sans-serif;
    margin-top: 2em;
    margin-bottom: 1em;
}

.module-description {
    text-align: left;
    margin-bottom: 30px;
}

.module-container {
    border-radius: 12px;
    margin-bottom: 10px;

    break-inside: avoid;
    
    display: flex;

    > .module-id {
        text-align: left;
        text-transform: uppercase;
        font-size: 1.6em;
        //letter-spacing: 5px;
        font-weight: 700;
        display: inline-block;
        align-self: flex-start;
        width: 100%;
        padding: 20px 0 10px 20px;
        line-height: 0.9em;

        > .module-class {
            font-weight: 300;
            font-size: 0.6em;
        }

        > .module-reqs {
            float: right;
            margin-top: -10px;
            margin-right: 10px;
            > div {
                background-repeat: no-repeat;
                background-size: 80%;
                background-position: center;

                width: 60px;
                height: 60px;
                text-align: center;
                color: black;
                display: flex;

                > div {
                    display: inline-block;
                    font-weight: 900;
                    font-size: 0.8em;
                    width: 100%;
                    align-self: center;
                    text-align: center;
                }
            }
            .module-size {
                background-image: url("/img/ui/ship-stats/modules.svg");
            }

            > .energy-req {
                background-image: url("/img/ui/ship-stats/energy.svg");
            }
        }

        > .module-type {
            font-size: 0.7em;
            letter-spacing: normal;
            font-weight: 400;
            margin-bottom: 20px;
            page-break-after:avoid;
            > span {
                font-size: 0.8em;
                text-transform: lowercase;
                font-style: italic;

                &:first-child {
                    margin-left: 10px;
                }

                &:not(:first-child) {
                    &:before {
                        content: ", ";
                    }
                }
            }
        }

        > .module-mode {
            display: inline-block;
            page-break-before:avoid;
            border: 1px solid rgba(255, 255, 255, 0.2);
            padding: 8px 0 0px 10px;
            border-radius: 12px;
            margin-right: 6px;
            margin-bottom: 15px;

            > .module-mode-name {
                font-size: 0.8em;
                font-weight: 200;
                margin-bottom: 2px;
            }
        }
    }

    &.logistics {
        background-image: linear-gradient(to bottom, #282c3400, #282c34ff), linear-gradient(to bottom, #a7a7a7, #637371);
    }
    &.operations {
        background-image: linear-gradient(to bottom, #282c3400, #282c34ff), linear-gradient(to bottom, #769e73, #383c20);
    }
    &.tactical {
        background-image: linear-gradient(to bottom, #282c3400, #282c34ff), linear-gradient(to bottom, #9e4280, #371930);
    }
    &.navigation {
        background-image: linear-gradient(to bottom, #282c3400, #282c34ff), linear-gradient(to bottom, #676daf, #202d4c);
    }
    &.weapon {
        background-image: linear-gradient(to bottom, #282c3400, #282c34ff),
            linear-gradient(to bottom, rgba(170, 111, 56, 1), rgba(115, 40, 58, 1));
    }
    &.support {
        background-image: linear-gradient(to bottom, #34332800, rgb(52, 51, 40)),
            linear-gradient(to bottom, rgb(97, 90, 32), rgb(115, 104, 40));
    }
}
.module-keyword {
    display: block;
    break-inside: avoid-column;
}

.module-attribute {
    font-weight: 400;
    font-size: 24px;
    display: inline-block;
    cursor: pointer;
    width: 70px;
    margin-right: 13px;
    margin-bottom: 13px;
    vertical-align: top;
    background-repeat: no-repeat;
    background-size: 85%;
    background-position: center top 6px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 4px;
    border-radius: 12px;

    > div {
        //margin-left: 66px;
        text-align: center;
        margin-top: 63px;
        margin-bottom: 4px;

        .module-attribute-name {
            font-size: 11px;
            text-transform: uppercase;
            letter-spacing: normal;
            line-height: 0.9;
        }
        .module-attribute-value {
            margin-top: 6px;
            font-size: 1.4em;
            letter-spacing: normal;
            font-weight: 600;
        }
    }

    &.agility {
        background-image: url("/img/ui/module-attributes/agility.png");
    }
    &.arc {
        background-image: url("/img/ui/module-attributes/arc.png");
    }
    &.arc-any {
        background-image: url("/img/ui/module-attributes/arc.png");
    }
    &.area {
        background-image: url("/img/ui/module-attributes/area.png");
    }
    &.capacitor {
        background-image: url("/img/ui/module-attributes/capacitor.png");
    }
    &.collision {
        background-image: url("/img/ui/module-attributes/collision.png");
    }
    &.damage {
        background-image: url("/img/ui/module-attributes/damage.png");
    }
    &.damage-shield {
        background-image: url("/img/ui/module-attributes/damage-shield.png");
    }
    &.damage-hull {
        background-image: url("/img/ui/module-attributes/damage-hull.png");
    }
    &.damage-reactor {
        background-image: url("/img/ui/module-attributes/damage-reactor.png");
    }
    &.energize {
        background-image: url("/img/ui/module-attributes/energize.png");
    }
    &.guided {
        background-image: url("/img/ui/module-attributes/guided.png");
    }
    &.instant {
        background-image: url("/img/ui/module-attributes/instant.png");
    }
    &.linear {
        background-image: url("/img/ui/module-attributes/linear.png");
    }
    &.linear-any {
        background-image: url("/img/ui/module-attributes/linear-any.png");
    }
    &.proximity {
        background-image: url("/img/ui/module-attributes/proximity.png");
    }
    &.range {
        background-image: url("/img/ui/module-attributes/range.png");
    }
    &.ripple {
        background-image: url("/img/ui/module-attributes/ripple.png");
    }
    &.speed {
        background-image: url("/img/ui/module-attributes/speed.png");
    }
    &.speed-inherit {
        background-image: url("/img/ui/module-attributes/speed-inherit.png");
    }
    &.storage {
        background-image: url("/img/ui/module-attributes/storage.png");
    }
    &.storage-hardened {
        background-image: url("/img/ui/module-attributes/storage-hardened.png");
    }
    &.supply {
        background-image: url("/img/ui/module-attributes/supply.png");
    }
    &.sweep {
        background-image: url("/img/ui/module-attributes/sweep.png");
    }
    &.sweep-any {
        background-image: url("/img/ui/module-attributes/sweep-any.png");
    }
    &.timers {
        background-image: url("/img/ui/module-attributes/timers.png");
    }
    &.vector {
        background-image: url("/img/ui/module-attributes/vector.png");
    }
    &.velocity {
        background-image: url("/img/ui/module-attributes/velocity.png");
    }
}

.module-token {
    display: inline-block;
    height: 110px;
    width: 90px;
    background-position: center bottom;
    background-size: 90%;
    background-repeat: no-repeat;
    margin-bottom: 16px;

    > div {
        //padding-top: 90px;
        text-align: center;
        font-size: 0.4em;
    }
}

.module-attribute-description {
    display: none;
    font-size: 14px;
    text-align: left;
    line-height: 16px;
}
.module-attribute-wrap {
    display: inline-block;
}
#attribute-detail-comp {
    .module-attribute-description {
        display: inline-block;
    }

    .module-attribute-wrap {
        padding: 16px;
        background-color: #222222;
        border-radius: 10px;
        width: 100vw;
        max-width: 240px;
    }
}

#specialists {
    // background-color: gray;
}
.specialist-col {
    > .row {
        margin: 0 10px 10px 10px;
    }
}
.levels-col {
    &:last-child {
        filter: drop-shadow(8px 5px 6px rgba(0, 0, 0, 0.5));
        margin-top: -10px;
        transform: translateX(-100%);
    }
    &:first-child {
        transform: translateX(100%);
    }
    .specialist-card {
        border-radius: 10px;
        background-color: #222222;
        background-size: 115% 115%;
        background-position: 45% 35%;

        &:not(:last-child) {
            //@debug: 10px;

            vertical-align: top;
        }

        &:last-child {
            margin-left: -10px;
            margin-top: 10px;
            vertical-align: bottom;
        }

        .specialist-card-img {
            width: 100%;
            visibility: hidden;
        }
    }
}

#commanders {
    h2.faction-name {
        text-align: center;
    }
}
#release-info {
    position: absolute;
    bottom: 0;
    left: 0;
    color: white;
    padding: 0 5px 3px 5px;
    font-size: 0.6em;
    background-color: black;
}

#item-modal {
    position: absolute;
    display: none;
    background-color: #000000cc;
    z-index: 1999;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
}
#item-modal-container {
    position: absolute;
    width: 90vw;
    z-index: 2000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

#attribute-modal {
    position: absolute;
    display: none;
    background-color: #000000cc;
    z-index: 1999;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
}
#attribute-modal-container {
    position: absolute;
    //width: 90vw;
    z-index: 2000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.comment-button {
    cursor: pointer;
    display: block;
    text-align: right;
    text-decoration: none;
    align-self: baseline;
    position: relative;
    //margin-right: 0;
    margin-right: 10px;
    margin-top: -20px;
    margin-bottom: 0;
    color: white;
    &:hover {
        color: gray;
    }
}

#item-detail-comp {
    //height: 40vh;
}

#item-comments {
    height: 30vh;
    width: 100%;
    margin-top: -40px;
    background-color: white;
    overflow: hidden;
    border-radius: 14px;
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    > div {
        width: 100%;
        height: 100%;
    }
}

.specialist-cards {
    display: flex;
}

#specialists {
    .comment-button {
        > svg {
            position: absolute;
        }
        top: -30px;
        right: 30px;
    }
}
#app-disclaimer {
    font-size: 0.8em;
    margin-top: 20px;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 20px;
    background-color: black;
    max-width: 40vh;
}

#module-attributes {
    text-align: left;

    page-break-before:avoid;

    .module-attribute-col {
        @media (min-width: 960px) {
            columns: 2;
            column-width: 50%;
            column-gap: 50px;
        }

        .module-attribute-container {
            padding: 20px;
            //margin-bottom: 18px;
            border-radius: 14px;
            background-color: unset;

            padding-left: 90px;
            margin-bottom: unset;
            margin-right: unset;
            width: 100%;

            .module-attribute-visual {
                padding: 0;
                width: 90%;
                border-radius: 14px;
            }
        }

        .module-attribute {
            background-size: 80px;
            background-position: top 26px left;
        }
    }
}

.faction-emblem {
    text-align: center;
    margin: auto;
    width: 10%;
}

.faction-description {
    line-height: 2em;
    margin-top: 1em;
    margin-bottom: 1.5em;
    margin-left: 10vw;
    margin-right: 10vw;
}

.ship-class-bc {
    .ship-stats {
        margin-top: 20vh;
    }
}

@media print {
    .comment-button {
        display: none;
    }
    #release-info {
        display: none;
    }

    body {
        position: unset;
        height: unset;
        overflow: scroll;
    }
    .app-container {
        min-height: unset;
        overscroll-behavior: unset;
    }
    nav,
    .panel-tabs,
    .meta-tabs,
    .utility-buttons {
        display: none !important;
    }
    #canvas {
        top: 13vh;
        height: 100vh;
        position: relative;
        page-break-after: always;
    }

    #missions {
        position: relative;
        height: unset;
        //overflow: scroll;
    }

    #mission-title {
        top: 30px;
        left: 46px;
        font-size: 48pt;
    }

    #meta-panel {
        position: relative;
        margin-left: 0;
        width: 100%;
        left: unset;
        height: unset;
        bottom: unset;
        top: unset;

        > div.rules-list {
            display: block;
            margin: 40px;
            width: 45%;
        }

        h1 {
            font-size: 3rem;
        }
        p,
        ul {
            font-size: 16pt;
            line-height: 20pt;
            font-weight: 200;
        }
    }

    #ships, #specialists, #modules {
        padding: 0;
    }

    #module-attributes {
        padding: 0;

        h2 {
            margin-top: 1em;
        }
    }

    .comment-button {
        display: none;
    }

    .row.specialist-container {
        margin-bottom: 2.2em;
    }
}
